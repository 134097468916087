import React from "react";
import ResendEmail from "../../components/cards/onboardingCard/resendEmail";
import { LiveChatWidget } from '@livechat/widget-react'
import "./style.scss";

const BrandWebsite = () => {
  return (
    <div className="bg bg-img">
      <div className="mid-card card-4">
        <ResendEmail />
      </div>
      {/* <LiveChatWidget
      license="14411064"
      visibility="minimized"
    /> */}
    </div>
  );
};

export default BrandWebsite;
