import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Title from "../../../text/title";
import PText from "../../../text/pText";
import "./style.scss";
import AvBtn from "../../../button/avBtn";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../../static/images/common/customer-form-lottie.json";
import {
  resendAndResetEmailHandler,
  register,
} from "../../../../actions/user/action";
import { navigate } from "gatsby";
import InputWithIcon from "../../../input/inputWithIcon";
import Loader from "../../../common/Loader";
import Toaster from "../../../common/Toaster";

// const  = () => {
const ResendEmail = ({ register, data, resendAndResetEmailHandler }) => {
  const [isMobile, setMobile] = useState(false);
  const [localstorageUserState, setLocalstorageUserState] = useState({});
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const [toastrType, setToastrType] = useState("success");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 576) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile(true) : setMobile(false);
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const localstorageUser = JSON.parse(
      localStorage.getItem("registeringUserEmail")
    );
    setLocalstorageUserState(localstorageUser);
  }, []);

  const submitEmail = async (e) => {

    e.preventDefault();
    setSubmitted(true);

    console.log(email);
    if (!loading) {
      setLoading(true);

      var user = await resendAndResetEmailHandler({
        oldEmail: localstorageUserState.email,
        newEmail: email,
      });
      if (user && !user.error) {
        setToastrMsg("Email has been changed!");
        setToastrType("success");
        doRefresh((prev) => prev + 1);
        setLoading(false);
        
        localStorage.setItem(
          "registeringUser",
          JSON.stringify({
            email: email,
          })
        );
        localStorage.setItem(
          "registeringUserEmail",
          JSON.stringify({
            email: email,
          })
        );

        setLocalstorageUserState({
          email: email,
        });

        setEmail("");
        setSubmitted(false);
      } else {
        setToastrMsg(user.errorData);
        setToastrType("error");
        doRefresh((prev) => prev + 1);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Toaster refresh={refresh} type={toastrType} message={toastrMsg} />
      <Loader isLoading={loading} />
      <div className="onboarding row check-mail">
        <div className="top">
          <img src="/images/common/plus_new_logo.svg" />
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12 r-image">
          <div className="d-flex justify-content-center lottie">
            {!isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 200, height: 200, margin: "auto" }}
              />
            )}
            {isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 120, height: 120, margin: "auto" }}
              />
            )}
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12  step-content">
          <div className="brand-focus-heading">
            <Title text="Don't see the confirmation email?" />
          </div>
          {/* <div className="d-flex">
          <LinkText text="We've sent an email to" color="#004E93" />
          <LinkText text=" micky.mx@gmail.com" bold={true} color="#004E93" />
        </div> */}
          <p className="text-center-cm">
            <span className="txt-span">We've sent an email to </span>
            <span className="txt-span bold-span">
              {localstorageUserState &&
                localstorageUserState.email &&
                localstorageUserState.email}{" "}
            </span>
          </p>
          <div className="d-flex p-space justify-center text-center-cm">
            <div>
              Click the link in the email to confirm your address
              <br /> and activate your account.
            </div>
          </div>
          <form
          onSubmit={submitEmail}
          className="mt-35 text-center-cm">
            <InputWithIcon
              placeholder={"Email address"}
              icon="/images/common/mail.svg"
              type="email"
              name="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
            />
            <span className="re-enter">or re-enter the correct email</span>
            {submitted &&
              (!email ||
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) && (
                <p className="error-class">Please enter valid email</p>
              )}
            <button 
            type="submit"
            className="resend-email-continue" 
            
            >
              Continue
            </button>
          </form>
          {/* <div className="d-flex p-space justify-center">
        <span className="txt-span bold-span">Didn't get the email?</span>
          
        </div> 
        <div className="d-flex p-space-min justify-center">
        <span className="txt-span">Check your spam folder or &nbsp;</span> 
        <span className="click-here">click here</span>
        </div>*/}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ data }) => ({
  data: data,
});
export default connect(mapStateToProps, {
  register,
  resendAndResetEmailHandler,
})(ResendEmail);
